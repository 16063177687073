import { Injectable } from '@angular/core';
import { Role, RoleValues } from '@models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class RoleAccessService {
  public hasAccess(roles: string[], path: string): boolean {
    const hasAccess = roles.findIndex((role) => this.access[role].includes(path));
    if (hasAccess >= 0) {
      return true;
    }

    return false;
  }

  public getRoles(): Role[] {
    return this.roles;
  }

  private access: { [K in RoleValues]: string[] } = {
    sysAdmin: [
      'my-account',
      'authoring',
      'assessments',
      'company-settings',
      'users',
      'user-details/:id',
      'exam-settings',
      'exam-settings/create',
      'exam-settings/details/:reference',
      'customization',
      'proctoring',
      'learners',
      'analytics',
    ],
    accAdmin: [
      'my-account',
      'authoring',
      'assessments',
      'company-settings',
      'users',
      'user-details/:id',
      'exam-settings',
      'exam-settings/create',
      'exam-settings/details/:reference',
      'customization',
      'proctoring',
      'learners',
      'analytics',
    ],
    proctor: [
      'my-account',
      'proctoring',
    ],
    externalProctor: [
      'my-account',
      'proctoring',
      'company-settings',
      'users',
      'user-details/:id',
      'exam-settings',
      'exam-settings/create',
      'exam-settings/details/:reference',
    ],

  };

  private roles: Role[] = [
    {
      value: 'sysAdmin',
      description: 'System Administrator',
    },
    {
      value: 'accAdmin',
      description: 'Account Administrator',
    },
    {
      value: 'proctor',
      description: 'Proctor',
    },
    {
      value: 'externalProctor',
      description: 'External Proctor',
    },
  ]
}
