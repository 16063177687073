import {
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import { ProctoringRules } from '@models/session.model';
import * as _ from 'lodash';

@Component({
  selector: 'exam-settings',
  templateUrl: './exam-settings.component.html',
  styleUrls: ['./exam-settings.component.scss'],
})

export class ExamSettingsComponent {
  public allowedRules: string[] = [];
  public forbiddenRules: string[] = [];

  @Input() public proctoringRules: ProctoringRules;
  @Input() public elongatedView: boolean = false;

  public readonly allowedProctoringRuleToText = {
    usePrivateTestArea: 'Use a private test area is not required',
    visibleFace: 'Face doesn’t have to be visible in camera view',
    doNotAllowInteractionWithOthers: 'You are allowed to interact with others',
    doNotAllowOtherMaterials: 'Other materials are allowed',
    doNotAllowNoiseMakingDevices: 'Noise-making devices are allowed',
    doNotAllowAdditionalDevices: 'Use of additional devices is allowed',
    // The remaining rules are not allowed to be turned off by design
  }

  public readonly forbiddenProctoringRuleToText = {
    usePrivateTestArea: 'Use of a private test area',
    visibleFace: 'Face should be visibile in camera view',
    doNotAllowInteractionWithOthers: 'Do not interact with others',
    doNotAllowOtherMaterials: 'Other materials are not allowed',
    doNotAllowNoiseMakingDevices: 'Turn off noise-making devices',
    doNotAllowAdditionalDevices: 'No additional devices allowed',
    allowMouseKeyboardMonitorOnly: 'Only mouse, keyboard and monitor allowed',
    doNotAllowAppsOrBroswerTabs: 'No other Apps or Browser tabs allowed',
    doNotAllowExamContentSharing: 'Sharing of exam content prohibited',
  }

  public ngOnChanges({ proctoringRules }: SimpleChanges): void {
    if (!_.isEmpty(proctoringRules.currentValue)
    && !_.isEqual(proctoringRules.currentValue, proctoringRules.previousValue)) {
      const validKeys = Object.keys(this.forbiddenProctoringRuleToText) as (keyof ProctoringRules)[];

      validKeys.forEach((rule) => {
        if (this.proctoringRules[rule]) {
          // If value is true then this rule is active hence forbidden.
          this.forbiddenRules.push(rule);
        } else {
          this.allowedRules.push(rule);
        }
      });
    }
  }
}
